import { useCallback } from 'react';
import { useLoadingStore } from 'modules/stores/loading/useLoadingStore';

interface IReturn {
    isLoading: boolean;
    start: () => void;
    stop: () => void;
}

const useLoading = (): IReturn => {
    const isLoading = useLoadingStore((store) => store.isLoading);
    const setIsLoading = useLoadingStore((store) => store.setIsLoading);

    const start = useCallback(() => {
        setIsLoading(true);
    }, [setIsLoading]);

    const stop = useCallback(() => {
        setIsLoading(false);
    }, [setIsLoading]);

    return {
        isLoading,
        start,
        stop
    };
};

export default useLoading;
