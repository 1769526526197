import * as React from 'react';
import { Box } from '@mui/material';

interface IProps {
    width?: string;
    display?: string;
    component?: React.ElementType;
    children: React.ReactNode | React.ReactNode[];
}

export const Mobile: React.FC<IProps> = ({
    width,
    children,
    component,
    display = 'block',
}) => {
    return (
        <Box component={component}
            display={{ xs: display, md: 'none' }}
            height='100%'
            width={width}
        >
            {children}
        </Box>
    );
};
