import ISession from 'modules/session/interfaces/ISession';
import { useSessionStore } from 'modules/stores/session/useSessionStore';

interface IReturn {
    isLoading: boolean;
    session?: ISession;
}

const useSession = (): IReturn => {
    const session = useSessionStore((store) => store.session);
    const isLoading = useSessionStore((store) => store.isLoading);

    return {
        isLoading,
        session,
    };
};

export default useSession;
