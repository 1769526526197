import { IIconSize } from 'components/map/Map';
import { encodeJson } from 'modules/url/encodeJson';
import { ERouterTabs as EAgentRouterTabs } from 'modules/route/enums/agent/detail/ERouterTabs';
import { ERouterTabs  as EAgencyRouterTabs } from 'modules/route/enums/agency/detail/ERouterTabs';
import { ERouterBranchType } from 'modules/route/enums/agency/detail/ERouterBranchType';
import { EGalleryGroupUrl } from 'components/gallery/enums/group/EGalleryGroupUrl';

export const INDEX = '/';
export const LOGIN = '/prihlasenie';
export const SEARCH_MAP = '/developerske-projekty-a-novostavby';
export const SEARCH_MAP_ID = (id: string): string => `/developerske-projekty-a-novostavby/${id}`;
export const SEARCH_MAP_MARKER = (isTeasing: boolean, isActive: boolean, iconSize: IIconSize, previewPhoto?: string): string => {
    const data = isTeasing
        ? {
            isTeasing,
            isActive,
            iconSize,
        } : {
            isTeasing,
            isActive,
            iconSize,
            imageUrl: previewPhoto,
        };

    return `/developerske-projekty-a-novostavby/marker/${encodeJson(data)}`;
};
export const LEAD_GENERATION = '/pomozeme-vam-s-predajom';
export const MARKET_REPORTS = '/trhove-reporty';
export const MARKET_INDEX = MARKET_REPORTS + '/vyvoj-trhu/';
export const MY_ACCOUNT = '/moje-konto';
export const MY_REAL_ESTATE = '/moja-nehnutelnost';
export const RECREATIONAL_HOUSES = '/rekreacne-domy/podla-regionov';
export const EDIT_MY_ACCOUNT = '/moje-konto/uprava';
export const FIRM_REGISTRATION_LOGIN = '/registracia-administracny-system';
export const FIRM_REGISTRATION_FORM = '/registracia-administracny-system-informacie';
export const FIRM_REGISTRATION_SUCCESS = '/registracia-administracny-system-dakujeme';
export const MY_SEARCH = '/ulozene-hladania';
export const FAVOURITE_ADVERTISEMENT = '/oblubene-inzeraty';
export const COMPARISON_OF_ADVERTISEMENT = '/porovnanie-inzeratov';
export const MY_ADS = '/moje-konto/inzeraty';
export const REAL_ESTATE_APPRAISAL = '/ocenovanie-nehnutelnosti';
export const ADDING_AD_FOR_SALE = '/pridanie/#predaj';
export const ADDING_AD_RENT = '/pridanie/#prenajom';
export const REAL_ESTATE_VALUATIONS = '/ocenene-nehnutelnosti';
export const LAND_VARIFICATION = '/trhove-reporty/marketplace/overovanie-pozemkov';
export const CONTRACTS = '/informacie';
export const HYPO_CALC = (hypoId?: number): string => hypoId ? `/vypocet-hypoteky/detail/${hypoId}` : '/vypocet-hypoteky';
export const INFORMATION_PAGE = '/nase-sluzby';
export const ABOUT_US_PAGE = '/o-nas';
export const GDPR_PAGE = '/gdpr';
export const DESIGN_SYSTEM = '/design-system';
export const DESIGN_SYSTEM_TYPOGRAPHY = DESIGN_SYSTEM + '/typography';
export const INFORMATION_PAGE_ADVERTISING = INFORMATION_PAGE + '/inzercia';
export const INFORMATION_PAGE_BANNERS = INFORMATION_PAGE + '/bannery';
export const INFORMATION_PAGE_ADDITIONAL_SERVICES = INFORMATION_PAGE + '/doplnkove-sluzby';
export const INFORMATION_PAGE_PR_OFFER = INFORMATION_PAGE + '/pr-ponuka';
export const INFORMATION_PAGE_SOCIAL_NETWORKS = INFORMATION_PAGE + '/socialne-siete';
export const INFORMATION_PAGE_PORTFOLIO = INFORMATION_PAGE + '/portfolio';
export const INFORMATION_PAGE_CONTACT_US = INFORMATION_PAGE + '/kontaktujte-nas';
export const SERVICES_PAGE = '/sluzby';
export const REAL_ESTATE_BRANCH_DETAIL_TAB = (branchId: string, slugName: string, branchType: ERouterBranchType, tab: EAgencyRouterTabs, page?: number): string => {
    if (page && page > 1) {
        return `/realitna-kancelaria/${slugName}/${branchType}/${tab}/${branchId}/${page}`;
    }

    return `/realitna-kancelaria/${slugName}/${branchType}/${tab}/${branchId}`;
};

export const REAL_ESTATE_AGENCIES_LISTING = (location?: string, page?: number): string => {
    let url = '/realitne-kancelarie';

    if (location) {
        url += `/${location}`;
    }

    if (page && page > 1) {
        url += `?page=${page}`;
    }

    return url;
};

export const REAL_ESTATE_AGENTS_LISTING = (location?: string, page?: number): string => {
    let url =  '/realitni-makleri';

    if (location) {
        url += `/${location}`;
    }

    if (page && page > 1) {
        url += `?page=${page}`;
    }

    return url;
};

export const REAL_ESTATE_AGENT_DETAIL = '/realitny-makler';
export const REAL_ESTATE_AGENT_DETAIL_TAB = (agentId: string, agentSlugName: string, tab: EAgentRouterTabs, page?: number): string => {
    if (tab === EAgentRouterTabs.ADVERTISEMENT) {
        if (page && page > 1) {
            return `${REAL_ESTATE_AGENT_DETAIL}/${agentSlugName}/${tab}/${agentId}/${page}`;
        } else {
            return `${REAL_ESTATE_AGENT_DETAIL}/${agentSlugName}/${tab}/${agentId}`;
        }
    }

    return `${REAL_ESTATE_AGENT_DETAIL}/${agentSlugName}/${tab}/${agentId}`;
};
export const REAL_ESTATE_LANDING_PAGE = '/realitne-kancelarie-makleri';
export const DEV_PROJECT_DETAIL = (devProjectId: string, slugName: string): string => `developersky-projekt/${devProjectId}/${slugName}`;
export const ADVERTISEMENT_SEARCH_OLD = '/vyhladavanie';
export const ADVERTISEMENT_SEARCH_DEFAULT = '/vysledky';
export const SEO_LINKS_DEFAULT = '/zoznam-realit';
export const ADVERTISEMENT_SEARCH = (pathname: string): string => {
    return [ADVERTISEMENT_SEARCH_DEFAULT, pathname].join('/');
};
// temporary added for new detail breadcrumbs, remove when new listing is out
export const ADVERTISEMENT_SEARCH_OLD_ROUTE = (pathname: string): string => {
    return ['', pathname].join('/');
};
// TODO only for new HP, remove when new search is out
export const ADVERTISEMENT_NEW_HP_TO_OLD_RESULTS = (pathname: string): string => {
    return [ADVERTISEMENT_SEARCH_OLD, pathname].join('/');
};
export const ADVERTISEMENT_DETAIL_WITH_GALLERY = (advertisementId: string, slugName = '', group: EGalleryGroupUrl): string => {
    const url = ['detail', 'galeria', group, advertisementId, slugName].filter(value => !!value).join('/');

    return `/${url}`;
};

export const ADVERTISEMENT_DETAIL = (advertisementId: string, slugName = '', getParams: Record<string, string> = {}): string => {
    let url = `/${['detail', advertisementId, slugName].filter(value => !!value).join('/')}`;

    const getParamsEntries = Object.entries(getParams);

    if (getParamsEntries.length) {
        url += `?${getParamsEntries.map(entry => entry.join('=')).join('&')}`;
    }

    return url;
};

// TODO will not be needed when detail is out - then delete this
export const ADVERTISEMENT_DETAIL_OLD_NSK = (advertisementId: string, slugName = '', isGalleryOpen?: boolean, oldFlag?: boolean, getParams: Record<string, string> = {}): string => {
    if (!advertisementId) {
        return `${ADVERTISEMENT_SEARCH_OLD}?v=1`;
    }

    let url = !isGalleryOpen
        ? `/${[advertisementId, slugName].filter(value => !!value).join('/')}`
        : `/foto/${[slugName, advertisementId].filter(value => !!value).join('/')}`;

    const finalGetParams: Record<string, string> = { ...getParams };

    if (oldFlag) {
        finalGetParams['old'] = '1';
    }

    const finalGetParamsEntries = Object.entries(finalGetParams);

    if (finalGetParamsEntries.length)
        url += `?${finalGetParamsEntries.map(entry => entry.join('=')).join('&')}`;

    return url;
};

export const INFORMATION_PAGE_ADVERTISING_WITH_PARAMS = (clientType: string, location: string, packageName: string): string => {
    return `${INFORMATION_PAGE_ADVERTISING}/${clientType}/${location}/${packageName}`;
};
