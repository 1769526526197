import { create } from 'zustand';

export interface IIsAllowed {
    googleTagManager: boolean;
    facebookPixel: boolean;
    gemius: boolean;
    rtbHouse: boolean;
    livemonitor: boolean;
    googleDoubleClick: boolean;
}

type CookieBarStore = {
    isAllowed: IIsAllowed;
    setIsAllowed: (key: string, value: boolean) => void;

};

export const useCookieBarStore = create<CookieBarStore>((set) => ({
    isAllowed: {
        googleTagManager: false,
        facebookPixel: false,
        gemius: false,
        rtbHouse: false,
        livemonitor: false,
        googleDoubleClick: false,
    },
    setIsAllowed: (key, value) => set((store) => ({
        isAllowed: {
            ...store.isAllowed,
            [key]: value,
        },
    })),
}));