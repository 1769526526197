import { create } from 'zustand';

type LoadingStore = {
    isLoading: boolean;
    setIsLoading: (flag: boolean) => void;
};

export const useLoadingStore = create<LoadingStore>((set) => ({
    isLoading: false,
    setIsLoading: (flag) => set(() => ({
        isLoading: flag,
    })),
}));