export const gtmPathToContentType: Record<string, string> = {
    '/': 'home',
    '/moje-konto': 'account',
    '/prihlasenie': 'account',
    '/nase-sluzby/doplnkove-sluzby': 'informational',
    '/nase-sluzby/inzercia': 'informational',
    '/nase-sluzby/bannery': 'informational',
    '/nase-sluzby/kontaktujte-nas': 'informational',
    '/nase-sluzby/portfolio': 'informational',
    '/nase-sluzby/pr-ponuka': 'informational',
    '/pomozeme-vam-s-predajom': 'ancillary service',
    '/rekreacne-domy/podla-regionov': 'ancillary service',
    '/developerske-projekty-a-novostavby': 'ancillary service',
    '/realitne-kancelarie-makleri': 'directory search results',
    '/realitne-kancelarie': 'directory search results',
    '/realitna-kancelaria': 'directory listing',
    '/realitni-makleri': 'directory search results',
    '/realitny-makler': 'directory listing',
    '/detail': 'classified listing',
    '/o-nas': 'informational',
    '/vysledky': 'classified search results',
    '/registracia-administracny-system': 'account',
    '/registracia-administracny-system-informacie': 'account',
    '/registracia-administracny-system-dakujeme': 'account',
    '/sluzby': 'informational',
    '/gdpr': 'informational',
    '/caroffice': 'informational',
};