import { create } from 'zustand';
import ISession from 'modules/session/interfaces/ISession';

type SessionStore = {
    session: ISession;
    setSession: (session: ISession) => void;

    isLoading: boolean;
    setIsLoading: (flag: boolean) => void;

    isInitialized: boolean;
    setIsInitialized: (flag: boolean) => void;
};

export const useSessionStore = create<SessionStore>((set) => ({
    session: null,
    setSession: (session) => set(() => ({
        session,
    })),

    isLoading: false,
    setIsLoading: (flag) => set(() => ({
        isLoading: flag,
    })),

    isInitialized: false,
    setIsInitialized: (flag) => set(() => ({
        isInitialized: flag,
    })),
}));